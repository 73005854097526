<template>
  <div style="overflow-x:hidden;">
    <div class="BoxHeight plr13 bbox">
      <div class="textc f17 fw700 text111 mt20 mb10">{{ title }}</div>
      <div class="text-blue textc fw700">
        倒计时：({{ time }}:{{ seconds }})
      </div>
      <!-- 选择题 -->
      <div class="train_content">
        <!-- 单选题 -->
        <div
          class="titles f14"
          v-for="node in questionList"
          :key="node.typeName"
        >
          <div class="mt20 fw700">{{ node.indexStr }}、{{ node.typeName }}</div>

          <div
            class="questionTtem bbox text666"
            v-for="question in node.questionList"
            :key="question.seq"
          >
            <!-- 题目 -->
            <div
              class="mt10 "
              v-if="
                '单选题' == node.typeName ||
                '多选题' == node.typeName ||
                '判断题' == node.typeName
              "
            >
              <span class="fw700">{{ question.seq }}.{{ question.content }}</span>
           
              <div style="width:270px;height:140px;margin-left:4%;"  v-if="question.optionUrl!=''">
                <img :src="question.optionUrl" style="width:100%;height:100%;">
              </div>
               </div>
            <!-- 单选题 -->
            <el-radio-group
              v-if="'单选题' == node.typeName"
              v-model="question.userAnswer"
              class="pl10"
            >
              <el-radio label="A" class="flex align-center mt10" >
                <div style="width:295px;">
                <span class="f14 " >A. {{ question.optionA }}</span>
                </div>
                </el-radio>
               <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionAUrl!=''" >
                         <img :src="question.optionAUrl" style="width:100%;height:100%;">
                        </div>
              <el-radio label="B" class="flex align-center mt10">
                   <div style="width:295px;">
                <span class="f14">B. {{ question.optionB }}</span>
                   </div>
                </el-radio>
              <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionBUrl!=''">
                         <img :src="question.optionBUrl" style="width:100%;height:100%;">
                        </div>
              <el-radio label="C" class="flex align-center mt10">
                 <div style="width:295px;">
                <span class="f14">C. {{ question.optionC }}</span>
                </div>
                </el-radio>
              <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionCUrl!=''">
                         <img :src="question.optionCUrl" style="width:100%;height:100%;">
                        </div>
              <el-radio label="D" class="flex align-center mt10" >
                  <div style="width:295px;">
                <span class="f14">D. {{ question.optionD }}</span>
                  </div>
                </el-radio>
               <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionDUrl!=''">
                         <img :src="question.optionDUrl" style="width:100%;height:100%;">
                        </div>
            </el-radio-group>

            <!-- 多选题 -->
            <el-checkbox-group
              v-if="'多选题' == node.typeName"
              v-model="question.userAnswers"
              class="pl10"
            >
              <el-checkbox label="A" class="dblock mt10">
                <div style="width:295px;">
                  <span class="f14"> A. {{ question.optionA }}</span>
                </div>
                </el-checkbox>
                <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionAUrl!=''" >
                    <img :src="question.optionAUrl" style="width:100%;height:100%;">
                </div>
              <el-checkbox label="B" class="dblock mt10">
                <div style="width:295px;">
                  <span class="f14"> B. {{ question.optionB }}</span>
                   </div>
                </el-checkbox >
                 <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionBUrl!=''" >
                    <img :src="question.optionBUrl" style="width:100%;height:100%;">
                </div>
              <el-checkbox label="C" class="dblock mt10">
                <div style="width:295px;">
                  <span class="f14">  C. {{ question.optionC }}</span>
                </div>
                </el-checkbox>
                 <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionCUrl!=''">
                    <img :src="question.optionCUrl" style="width:100%;height:100%;">
                  </div>
              <el-checkbox label="D" class="dblock mt10">
                <div style="width:295px;">
                  <span class="f14"> D. {{ question.optionD }}</span>
                </div>
                </el-checkbox>
               <div style="width:270px;height:140px;margin-left:8%;"  v-if="question.optionDUrl!=''">
                         <img :src="question.optionDUrl" style="width:100%;height:100%;">
                        </div>
            </el-checkbox-group>

            <!-- 判断题 -->
            <el-radio-group
              v-if="'判断题' == node.typeName"
              v-model="question.userAnswer"
              class="mt10 mb10 pl10 flex align-center"
            >
              <el-radio label="对" class="flex align-center"
                ><i class="f20 el-icon-check"></i
              ></el-radio>
              <el-radio label="错" class="flex align-center"
                ><i class="f20 el-icon-close"></i
              ></el-radio>
            </el-radio-group>

            <!-- 填空题 -->
            <div
              v-if="'填空题' == node.typeName"
              class="mt10 "
              style="outline: none"
            > 
            <div class="flex">
              <div>{{ question.seq }}.</div>
              <div
                class="detail fw700"
                ref="detail"
                :data-index="question.seq"
                v-html="question.content"
              ></div>
              </div>
                <div style="width:270px;height:140px;margin-left:4%;margin-top:1%;"  v-if="question.optionUrl!=''">
                <img :src="question.optionUrl" style="width:100%;height:100%;">
               </div>
            </div>

            <!-- 解答题 -->
            <div v-if="'解答题' == node.typeName" class="mt10 mb15">
              <p class="mt10  fw700">
                <span style="min-width:10px;">{{ question.seq }}.</span>
                <span v-html="question.content"></span>
              </p>
              <div style="width:270px;height:140px;margin-left:4%;margin-top:1%;"  v-if="question.optionUrl!=''">
                <img :src="question.optionUrl" style="width:100%;height:100%;">
               </div>
              <span class="text333 fw700">解答:</span>
              <el-input
                type="textarea"
                v-model="question.userAnswer"
                placeholder="请输入您的答案"
                :autosize="{ minRows: 2 }"
                class="mt5"
              ></el-input>
            </div>
          </div>
        </div>
        <!-- 完成提交 -->
        <div class="textc mt30 mb30">
          <el-button type="primary" @click="gotoSumbit" class="wp80 f16 Btn"
            >立即提交</el-button
          >
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Footer from "../../components/footer.vue";
import { Dialog } from "vant";
export default {
  // components: { Footer },
  data() {
    return {
      exameCode: "",
      title: "",
      time: 0,
      seconds: 60,
      status: false,
      countDownTimer: "",
      recordCode: "",
      questionList: [],
    };
  },
  methods: {
    /**
     * 提交考试信息
     */
    gotoSumbit() {
      Dialog.confirm({
        title: "温馨提示",
        message: "是否立即提交测试题？",
      }).then(() => {
        this.uploadAnswer();
      });
    },
    uploadAnswer() {
      clearInterval(this.countDownTimer);
      var answers = "";
      //获取填空题答案
      $(".detail").each(function () {
        var seq = $(this)[0].dataset.index;
        var content = $(this).children(".gapFilling")[0].innerText;
        if ("" == answers) {
          answers = seq + ":" + content;
        } else {
          answers = answers + ";" + seq + ":" + content;
        }
      });
      //获取除填空题外的用户的答案
      this.questionList.forEach((node) => {
        if (
          typeof node.questionList === undefined ||
          null == node.questionList ||
          0 == node.questionList
        )
          return;
        node.questionList.forEach((question) => {
          if ("单选题" == node.typeName) {
            if ("" == answers) {
              answers = question.seq.toString() + ":" + question.userAnswer;
            } else {
              answers =
                answers +
                ";" +
                question.seq.toString() +
                ":" +
                question.userAnswer;
            }
          }
          if ("多选题" == node.typeName) {
            if (question.userAnswers.length > 0) {
              var multi = "";
              var tempAnswer = question.userAnswers.sort();
              for (var i = 0; i < tempAnswer.length; i++) {
                if (i == 0) {
                  multi = tempAnswer[i];
                } else {
                  multi = multi + tempAnswer[i];
                }
              }
              if ("" == answers) {
                answers = question.seq.toString() + ":" + multi;
              } else {
                answers = answers + ";" + question.seq.toString() + ":" + multi;
              }
            }
          }
          if ("判断题" == node.typeName) {
            if ("" == answers) {
              answers = question.seq.toString() + ":" + question.userAnswer;
            } else {
              answers =
                answers +
                ";" +
                question.seq.toString() +
                ":" +
                question.userAnswer;
            }
          }
          if ("解答题" == node.typeName) {
            if ("" == answers) {
              answers = question.seq.toString() + ":" + question.userAnswer;
            } else {
              answers =
                answers +
                ";" +
                question.seq.toString() +
                ":" +
                question.userAnswer;
            }
          }
        });
      });
      var sendData = {};
      sendData.examCode = this.examCode;
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.answers = answers;
      sendData.recordCode = this.recordCode;
      var that = this;
      this.$http
        .post("memberExam/uploadMemberAnswer", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            var examResult = res.data.data.split(":");
            that.$router.push({
              path: "/examCompleted",
              query: {
                isRead: examResult[0],
                score: examResult[1],
                examTitle: that.examTitle,
              },
            });
          }
        });
    },
    /**
     * 加载考试题目信息
     */
    loadExamQuestionList() {
      var sendData = {};
      sendData.examCode = this.examCode;
      sendData.recordCode = this.recordCode;
      var that = this;
      that.$http
        .post("examQuestion/queryExamQuestionList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            //判断当前试卷有无填空题
            res.data.data.forEach((element) => {
              if (
                "填空题" == element.typeName &&
                element.questionList.length > 0
              ) {
                element.questionList.forEach((element) => {
                  element.content = element.content.replace(
                    new RegExp("##", "g"),
                    '<div contenteditable="true" class="gapFilling"></div>'
                  );
                });
              }
            });
            that.questionList = res.data.data;
          }
        });
    },
    /**
     * 倒计时
     */
    countDown() {
      if (0 == this.seconds) {
        this.seconds = 59;
        this.time = this.time - 1;
      } else {
        this.seconds = this.seconds - 1;
      }
      if (this.time == 0 && this.seconds == 1) {
        clearInterval(this.countDownTimer);
        this.gotoSumbit();
      }
    },
  },
  created() {
    clearInterval(this.countDownTimer);
    this.examCode = this.$route.query.examCode;
    this.title = this.$route.query.title;
    this.recordCode = this.$route.query.recordCode;
    this.time = parseInt(this.$route.query.time) - 1;
    this.loadExamQuestionList();
    this.countDownTimer = setInterval(this.countDown, 1000);
  },
};
</script>

<style scoped>
.gapFilling {
  border-bottom: 1px solid #333;
  min-width: 100px;
  outline: none;
  color: #333;
  text-align: center;
}
.detail >>> .gapFilling {
  border-bottom: 1px solid rgb(155, 154, 154);
  min-width: 100px;
  outline: 0px;
  display: inline-block;
  -webkit-user-select: text !important;
  padding: 0 5px;
}
.newLine{
  word-break: break-all;
  word-wrap: break-word;
  /* height: 40px; */
}
::v-deep .el-radio__label {
  width: 100% !important;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 18px;
   /* word-wrap: break-word !important;     */
   word-break:break-all;
}
</style>